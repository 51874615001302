<template>
  <div>
    <b-modal
      :id="usersModal.id"
      ref="modal-new-user"
      :title="usersModal.title"
      :content="usersModal.content"
      no-close-on-backdrop
      hide-footer
    >
      <br>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>

            <b-col md="12">
              <b-form-group>
                <b-badge variant="primary">Dados do Usuário
                </b-badge>
                <br>
                <br>
                <!-- Username -->

                <label>Username</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Username"
                >
                  <b-form-input
                    v-model="itemUsername"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Username"
                    :readonly="readonly"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Must be a valid email -->
            <b-col md="12">
              <b-form-group>
                <label>E-mail</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="itemEmail"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  Name -->
            <b-col md="12">
              <b-form-group>
                <label>Nome</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Nome"
                >
                  <b-form-input
                    v-model="itemFirstName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  Last Name -->
            <b-col md="12">
              <b-form-group>
                <label>Sobrenome</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Sobrenome"
                >
                  <b-form-input
                    v-model="itemLastName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Sobrenome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- PASSWORD -->
            <b-col md="12">
              <b-form-group>
                <label>Senha</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Senha"
                >
                  <b-form-input
                    v-model="itemPasswd"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Senha"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- V-select CNPJ/ EMPRESA -->
            <b-col md="12">
              <b-form-group
                label="Empresa / CNPJ"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="cnpj"
                >
                  <v-select
                    v-model="itemCnpj"
                    placeholder="Selecione CNPJ"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="options"
                    :state="errors.length > 0 ? false:null"
                    multiple
                    @input="getClient()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <div>
              <b-row>
                <!-- Radio Button -->
                <b-col
                  class="ml-1"
                >
                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                    label=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="radio"
                    >
                      <b-form-radio-group
                        v-model="itemSelected"
                        :options="options1"
                        :state="errors.length > 0 ? false:null"
                        :aria-describedby="ariaDescribedby"
                        name="radio"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                      <!-- check if the select is selecting corre -->
                      <!-- <div class="">
                        Selected: <strong>{{ selected }}</strong>
                      </div> -->

                    </validation-provider>
                  </b-form-group>
                  <!-- Switch Checkbox -->
                  <b-form-group>

                    <b-form-checkbox
                      v-model="itemEnable"
                      name="switch"
                      switch
                    >
                      {{ checkboxText }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <!-- "Cadastrar Usuário" Button -->
            <b-col md="12">
              <b-button
                :id="registerButton.id"
                :content="registerButton.content"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ registerButton.content }}
              </b-button>

            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BBadge, BModal, BFormRadioGroup, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, min, digits, length,
} from '@validations'

export default {
  name: 'ModalNewUsers',
  components: {

    BFormCheckbox,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BBadge,
    BModal,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      itemSelected: 'OPERATOR',
      options1: [
        { text: 'admin', value: 'CLIENT_ADMIN' },
        { text: 'operador', value: 'OPERATOR' },
        { text: 'adejo', value: 'ADMIN' },
      ],
      options: [],
      itemUsername: null,
      itemFirstName: null,
      itemEnable: true,
      itemEmail: null,
      itemLastName: null,
      itemCnpj: [],
      itemTr_url: null,
      required,
      confirmed,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      usersModal: {
        id: 'modal-prevent-closing',
        title: 'Cadastro de Usuário',
        content: '',
      },
      registerButton: {
        id: 'register-button',
        content: 'Cadastrar',
      },
    }
  },
  computed: {
    checkboxText() {
      return this.itemEnable ? 'Ativo' : 'Inativo'
    },
  },
  created() {
    this.getClient()
  },
  methods: {
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    validationForm() {
      this.readonly = false
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.usersModal.title === 'Cadastro de Usuário') {
            this.postNewUser()
          } else {
            this.updateUser()
          }
        }
      })
    },
    hideModal() {
      this.$refs['modal-new-user'].hide()
    },
    async getClient() {
      try {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const allowedCNPJ = userData.cnpj
        const allowedGroup = userData.group

        const responseFiltered = []
        const res = await axios.get('/companyuser/?skip=0&limit=100')
        console.log(res.data)
        res.data.forEach(item => {
          // Validating allowed CNPJ
          for (let i = 0; i < allowedCNPJ.length; i += 1) {
            if (allowedCNPJ[i] === item.id_cnpj && allowedGroup !== 'admin') {
              console.log(allowedCNPJ[i])
              responseFiltered.push({
                value: item.id_cnpj,
                label: `${item.name}`,
              })
            }
          }

          // If the user is admin
          if (allowedGroup === 'admin') {
            responseFiltered.push({
              value: item.id_cnpj,
              label: `${item.name}`,
            })
          }
        })
        console.log('getClient() method called')
        // console.log(JSON.parse(JSON.stringify(this.itemCnpj)))
        const cnpjArray = this.itemCnpj.map(item => item.value)

        console.log(cnpjArray) // Log the array containing only the CNPJ values
        this.options = responseFiltered
      } catch (error) {
        console.log(error)
      }
    },
    async postNewUser() {
      console.log('chamou postNewUser')
      try {
        // Getting values for POST
        const Email = this.itemEmail
        const username = this.itemUsername
        const enabled = this.itemEnable
        const firstName = this.itemFirstName
        const lastName = this.itemLastName
        const userPassword = this.itemPasswd
        // Extract the 'value' property (CNPJ) from each object using map
        const cnpjArray = this.itemCnpj.map(item => item.value)

        // Create the 'attributes' object with the "CNPJ" key
        const attributes = {
          CNPJ: cnpjArray,
          ROLE: this.itemSelected,
        }
        console.log(attributes)

        // End getting values for post

        await axios.post('/auth/user', {

          email: Email,
          username,
          enabled,
          firstName,
          lastName,
          attributes,
          user_password: userPassword,

        })
        // atualiza a tabela btable
        this.$parent.createUsersTable()
        this.toast('b-toaster-top-right', true, 'success', `Usuário ${this.itemUsername} cadastrado com sucesso`, 'Cadastro Usuário')

        // deixa o campos em branco após salvo.
        this.itemUsername = ''
        this.itemEnable = ''
        this.itemEmail = ''
        this.itemFirstName = ''
        this.itemLastName = ''
        this.userPassword = ''
        this.itemCnpj = ''
        this.itemSelected = ''
        this.$parent.totalRows = this.$parent.items_users.length // pagination
        this.$parent.currentPage = 1
        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        // foi colocado aqui pois não é correto adicionar 2 eventos no onclick, então chamamos um método dentro do outro, toast
        // dentro do addClick
        this.hideModal() // esconde o modal se cadastrado com sucesso.
      } catch (error) {
        if (error.response) {
          const statusCode = error.response.status
          console.log('Status code:', statusCode)
          console.log('Response data:', error.response.data)

          // Mostrar o código de status no front-end
          this.toast('b-toaster-top-right', true, 'warning', `Erro ${statusCode}: ${error.response.data.detail}`, 'Atenção')
        } else {
          console.log(error)
          // Se não houver uma resposta do servidor, trate o erro de forma adequada
        }
      }
    },
    async updateUser() {
      const userId = this.usersModal.title
      console.log(userId)

      const cnpjArray = this.itemCnpj.map(item => item.value)

      const fields = {
        email: this.itemEmail,
        username: this.itemUsername,
        enabled: this.itemEnable,
        firstName: this.itemFirstName,
        lastName: this.itemLastName,
        attributes: {
          CNPJ: cnpjArray,
          ROLE: this.itemSelected,
        },
        user_password: this.itemPasswd,
      }
      console.log(fields)
      try {
        await axios.patch(`/auth/user/${userId}`, fields)

        this.toast('b-toaster-top-right', true, 'success', `${this.itemUsername} Atualizado com sucesso`, 'Cadastro Empresa')
        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        // foi colocado aqui pois não é correto adicionar 2 eventos no onclick, então chamamos um método dentro do outro, toast
        // dentro do addClick

        // Atualizar table
        this.$parent.createUsersTable()
        this.$refs['modal-new-user'].hide()
      } catch (error) {
        console.log('Status code:', error.response.status)
        console.log('Response data:', error.response.data)
        if (error.response.status === 500) {
          this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        } else {
          this.toast('b-toaster-top-right', true, 'warning', ` ${error}`, 'Cadastro Usuário')
        }
      }
      console.log(fields)
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.checkbox-group-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;

.vs__deselect-button {
    background-color: red; // Replace with your desired color
    color: white; // Replace with your desired color for text
  }
}
</style>
